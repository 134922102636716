import _useExportSegment_TargetingCommon_Mutation from "./__generated__/useExportSegment_TargetingCommon_Mutation.graphql";
import { useMutation } from 'react-relay';
import { useCurrentCompanyId } from '@attentive/acore-utils';
import { graphql } from '@attentive/data';
import { useTargetingCommonLogger } from '../logger/useTargetingCommonLogger';
import exportSegmentMutation from './__generated__/useExportSegment_TargetingCommon_Mutation.graphql';
_useExportSegment_TargetingCommon_Mutation;
export const useExportSegment = () => {
  const companyId = useCurrentCompanyId();
  const {
    logError
  } = useTargetingCommonLogger();
  const [commitMutation, isInFlight] = useMutation(exportSegmentMutation);

  const commitExportSegment = _ref => {
    let {
      input,
      onSuccess,
      onError
    } = _ref;
    commitMutation({
      variables: {
        input: {
          companyId,
          ...input
        }
      },
      onCompleted: (response, errors) => {
        var _errors$;

        const {
          exportSegment
        } = response;

        if ((exportSegment === null || exportSegment === void 0 ? void 0 : exportSegment.__typename) === 'ExportSegmentSuccess' && !(errors !== null && errors !== void 0 && errors.length) && exportSegment.segmentId) {
          return onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        }

        const newError = new Error((errors === null || errors === void 0 ? void 0 : (_errors$ = errors[0]) === null || _errors$ === void 0 ? void 0 : _errors$.message) || 'Export segment failed');
        logError(newError);
        return onError === null || onError === void 0 ? void 0 : onError();
      },
      onError: error => {
        logError(error);
        return onError === null || onError === void 0 ? void 0 : onError();
      }
    });
  };

  return [commitExportSegment, isInFlight];
};