import { SegmentSubscriberCount } from '../../constants/interfaces';

export const currentSegmentCountResponse: SegmentSubscriberCount = {
  activeSubscribers: 10000,
  activeSubscribersNotFatigued: 9900,
};

export const currentSegmentCountResponseV2 = {
  sms: {
    activeSubscribers: 32476,
    activeSubscribersNotFatigued: 31002,
  },
  email: {
    activeSubscribers: 29998,
    activeSubscribersNotFatigued: 27654,
  },
  smsAndEmail: {
    activeSubscribers: 22456,
    activeSubscribersNotFatigued: 21098,
  },
};
