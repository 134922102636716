import { SegmentCountMetric } from '../../hooks';
import { Channel } from '../../mutations/__generated__/useExportSegment_TargetingCommon_Mutation.graphql';

export type SubscriberChannelRadioType = 'ALL' | 'TEXT' | 'EMAIL' | 'MULTICHANNEL';

type SubscriberChannelMapType = Record<
  SubscriberChannelRadioType,
  { label: string; metric: SegmentCountMetric | undefined; channelFilters: Channel[] }
>;

export const subscriberChannelDataMap: SubscriberChannelMapType = {
  ALL: { label: 'All subscribers', metric: undefined, channelFilters: [] },
  TEXT: { label: 'SMS', metric: 'sms', channelFilters: ['CHANNEL_TEXT'] },
  EMAIL: { label: 'Email', metric: 'email', channelFilters: ['CHANNEL_EMAIL'] },
  MULTICHANNEL: {
    label: 'Multi-channel',
    metric: 'smsAndEmail',
    channelFilters: ['CHANNEL_TEXT', 'CHANNEL_EMAIL'],
  },
};
