import {
  ActionChannel,
  ActionFilter,
  ActionSource,
  UserPropertyType,
  HasVerbType,
  IsVerbType,
  OperatorComparator,
  OperatorType,
  Segment,
  SegmentComponentType,
  SiteCondition,
  Source,
  SyncStatus,
  TimeComparatorType,
  QuantityComparator,
  Vendor,
} from '../../constants';

export const completeSegment: Segment = {
  anyComponentSyncing: false,
  syncStatus: SyncStatus.SYNCED,
  expressions: [
    {
      id: 1,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 1,
          type: SegmentComponentType.CUSTOM,
          description: 'has joined over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.JOINED,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
          },
        },
        {
          id: 2,
          type: SegmentComponentType.CUSTOM,
          description: 'has clicked at least once over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.CLICKED,
            subscriberActionChannel: ActionChannel.TEXT,
            subscriberActionSource: ActionSource.ATTENTIVE,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
            frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
          },
        },
      ],
    },
    {
      id: 2,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 3,
          type: SegmentComponentType.URL_VIEWED,
          description: 'has visited a specific page http://foo.com',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.VISITED_SITE,
            propertyAttributeCondition: SiteCondition.SPECIFIC_PAGE,
            pattern: 'http://foo.com',
          },
        },
        {
          id: 4,
          type: SegmentComponentType.PRODUCT_DATA,
          description: 'has added to cart at least once over all time',
          syncing: false,
          parameters: {
            hasVerb: HasVerbType.HAS,
            subscriberAction: ActionFilter.ADDED_TO_CART,
            timeComparator: TimeComparatorType.OVER_ALL_TIME,
            frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
            productDataAttributeValues: [
              {
                attribute: 'PRODUCT_CATEGORY',
                values: [{ optionValue: 'Bandana', displayName: 'Bandana' }],
                isVerb: IsVerbType.IS,
              },
              {
                attribute: 'PRODUCT_SIZE',
                values: [{ optionValue: 'S', displayName: 'S' }],
                isVerb: IsVerbType.IS,
              },
            ],
          },
        },
      ],
    },
  ],
  description: 'Mock Mixed And / Or Segment',
  id: 1234,
  name: 'My And / Or Segment',
  operator: OperatorType.AND,
  readOnly: false,
  source: Source.DYNAMIC,
  created: 1591377585,
  updated: 1591377585,
};

export const segmentWithCustomAttributes: Segment = {
  id: 5678,
  name: 'All of my favorite paper towel brands',
  description: 'Lots of classics here, folks',
  operator: OperatorType.AND,
  anyComponentSyncing: false,
  readOnly: false,
  source: Source.DYNAMIC,
  updated: 1661899839,
  created: 1661895062,
  syncStatus: SyncStatus.SYNCED,
  expressions: [
    {
      id: 7828,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 212218,
          type: SegmentComponentType.USER_PROPERTY,
          description: 'Favorite Paper Towel Brand is Scotts',
          syncing: false,
          parameters: {
            isVerb: IsVerbType.IS,
            userPropertyType: UserPropertyType.CUSTOM_PROPERTY_ID,
            displayName: 'Favorite Paper Towel Brand',
            attribute: '213',
            userPropertyValues: [{ optionValue: 'Scotts', displayName: 'Scotts', deleted: false }],
            locations: [],
            deleted: false,
          },
        },
        {
          id: 212219,
          type: SegmentComponentType.PROFILE,
          description: 'Yotpo Perks redeemed is more than 10',
          syncing: false,
          parameters: {
            isVerb: IsVerbType.IS,
            profileAttribute: 'perks_redeemed',
            vendor: Vendor.YOTPO,
            textValues: [],
            quantityComparator: QuantityComparator.MORE_THAN,
            quantity: 10.0,
            locations: [],
          },
        },
        {
          id: 212220,
          type: SegmentComponentType.USER_PROPERTY,
          description: 'Favorite Paper Towel Brand is Brawny',
          syncing: false,
          parameters: {
            isVerb: IsVerbType.IS,
            userPropertyType: UserPropertyType.CUSTOM_PROPERTY_ID,
            displayName: 'Favorite Paper Towel Brand',
            attribute: '213',
            userPropertyValues: [{ optionValue: 'Brawny', displayName: 'Brawny', deleted: false }],
            locations: [],
            deleted: false,
          },
        },
        {
          id: 212221,
          type: SegmentComponentType.USER_PROPERTY,
          description: 'custom attribute text is my text!',
          syncing: false,
          parameters: {
            isVerb: IsVerbType.IS,
            userPropertyType: UserPropertyType.CUSTOM_PROPERTY_ID,
            displayName: 'custom attribute text',
            attribute: 'custom attribute text',
            userPropertyValues: [
              { optionValue: 'my text!', displayName: 'my text!', deleted: false },
            ],
            locations: [],
            deleted: false,
          },
        },
        {
          id: 212226,
          type: SegmentComponentType.USER_PROPERTY,
          description: 'Favorite Paper Towel Brand is Gruffalo',
          syncing: false,
          parameters: {
            isVerb: IsVerbType.IS,
            userPropertyType: UserPropertyType.CUSTOM_PROPERTY_ID,
            displayName: 'Favorite Paper Towel Brand',
            attribute: '213',
            userPropertyValues: [
              { optionValue: 'Gruffalo', displayName: 'Gruffalo', deleted: false },
            ],
            locations: [],
            deleted: false,
          },
        },
      ],
    },
    {
      id: 7829,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 212222,
          type: SegmentComponentType.USER_PROPERTY,
          description: 'Favorite Paper Towel Brand is Lumberjack Paperstacks',
          syncing: false,
          parameters: {
            isVerb: IsVerbType.IS,
            userPropertyType: UserPropertyType.CUSTOM_PROPERTY_ID,
            displayName: 'Favorite Paper Towel Brand',
            attribute: '213',
            userPropertyValues: [
              {
                optionValue: 'Lumberjack Paperstacks',
                displayName: 'Lumberjack Paperstacks',
                deleted: false,
              },
            ],
            locations: [],
            deleted: false,
          },
        },
      ],
    },
  ],
  journeys: [],
  campaigns: [],
};

export const segmentWithOneAttribute = {
  id: 9012,
  name: 'A short segment',
  description: '',
  operator: OperatorType.AND,
  anyComponentSyncing: false,
  readOnly: false,
  source: Source.DYNAMIC,
  updated: 1661899839,
  created: 1661895062,
  syncStatus: SyncStatus.SYNCED,
  expressions: [
    {
      id: 7828,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 212219,
          type: SegmentComponentType.PROFILE,
          description: 'Yotpo Perks redeemed is more than 10',
          syncing: false,
          parameters: {
            isVerb: IsVerbType.IS,
            profileAttribute: 'perks_redeemed',
            vendor: Vendor.YOTPO,
            textValues: [],
            quantityComparator: QuantityComparator.MORE_THAN,
            quantity: 10.0,
            locations: [],
          },
        },
      ],
    },
    {
      id: 7829,
      operator: OperatorType.OR,
      segmentComponents: [
        {
          id: 212222,
          type: SegmentComponentType.USER_PROPERTY,
          description: 'Favorite Paper Towel Brand is Lumberjack Paperstacks',
          syncing: false,
          parameters: {
            isVerb: IsVerbType.IS,
            userPropertyType: UserPropertyType.CUSTOM_PROPERTY_ID,
            displayName: 'Favorite Paper Towel Brand',
            attribute: '213',
            userPropertyValues: [
              {
                optionValue: 'Lumberjack Paperstacks',
                displayName: 'Lumberjack Paperstacks',
                deleted: false,
              },
            ],
            locations: [],
            deleted: false,
          },
        },
      ],
    },
  ],
  journeys: [],
  campaigns: [],
};

export const segmentWithRecharge = {
  id: 34222,
  name: 'Recharge segment',
  description: '',
  operator: 'AND',
  source: 'DYNAMIC',
  syncStatus: 'SYNCED',
  templateId: null,
  expressions: [
    {
      operator: 'OR',
      segmentComponents: [
        {
          parameters: {
            userPropertyType: 'THIRD_PARTY_PROPERTY',
            attribute: 'subscriptions',
            propertyDataType: 'SEGMENT_DATA_TYPE_LIST',
            vendor: 'VENDOR_RECHARGE',
            quantityComparator: 'MORE_THAN',
            nestedPropertyFilters: [
              {
                fieldName: 'status',
                fieldDataType: 'SEGMENT_DATA_TYPE_TEXT',
                displayName: '',
                stringCondition: {
                  verb: 'IS',
                  values: ['ACTIVE'],
                },
              },
            ],
            quantity: 5,
            productDataAttributeValues: [
              {
                attribute: 'PRODUCT_TAG',
                values: [
                  {
                    optionValue: '9',
                    displayName: '9',
                  },
                  {
                    optionValue: 'ATTV_GLOBAL_EXCLUSION_TAG',
                    displayName: 'ATTV_GLOBAL_EXCLUSION_TAG',
                  },
                ],
                isVerb: 'IS',
              },
            ],
          },
          type: 'USER_PROPERTY',
        },
      ],
    },
    {
      operator: 'OR',
      segmentComponents: [
        {
          parameters: {
            userPropertyType: 'THIRD_PARTY_PROPERTY',
            attribute: 'subscriptions',
            propertyDataType: 'SEGMENT_DATA_TYPE_LIST',
            vendor: 'VENDOR_RECHARGE',
            nestedPropertyFilters: [
              {
                fieldName: 'next_charge_scheduled_at',
                fieldDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
                displayName: '',
                timeCondition: {
                  comparator: 'IN_THE_LAST',
                  units: 'DAYS',
                  duration: 30,
                },
              },
            ],
          },
          type: 'USER_PROPERTY',
        },
      ],
    },
    {
      operator: 'OR',
      segmentComponents: [
        {
          parameters: {
            userPropertyType: 'THIRD_PARTY_PROPERTY',
            attribute: 'subscriptions',
            propertyDataType: 'SEGMENT_DATA_TYPE_LIST',
            vendor: 'VENDOR_RECHARGE',
            nestedPropertyFilters: [
              {
                fieldName: 'created_at',
                fieldDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
                displayName: '',
                timeCondition: {
                  comparator: 'BEFORE',
                  epochTime: 1669010400,
                },
              },
            ],
          },
          type: 'USER_PROPERTY',
        },
      ],
    },
    {
      operator: 'OR',
      segmentComponents: [
        {
          parameters: {
            userPropertyType: 'THIRD_PARTY_PROPERTY',
            attribute: 'subscriptions',
            propertyDataType: 'SEGMENT_DATA_TYPE_LIST',
            vendor: 'VENDOR_RECHARGE',
            nestedPropertyFilters: [
              {
                fieldName: 'cancelled_at',
                fieldDataType: 'SEGMENT_DATA_TYPE_TIMESTAMP',
                displayName: '',
                timeCondition: {
                  comparator: 'BEFORE',
                  epochTime: 1669010400,
                },
              },
              {
                fieldName: 'cancellation_reason',
                fieldDataType: 'SEGMENT_DATA_TYPE_TEXT',
                displayName: 'Churn reason',
                stringCondition: {
                  verb: 'IS',
                  values: ['I already have more than I need'],
                },
              },
            ],
          },
          type: 'USER_PROPERTY',
        },
      ],
    },
  ],
};

export const segmentWithSmsConditions = {
  id: 106173,
  name: 'SC SMS segment',
  description: '',
  operator: 'AND',
  source: 'DYNAMIC',
  updated: 1734366165,
  created: 1734366165,
  syncStatus: 'SYNCED',
  expressions: [
    {
      id: 82704,
      operator: 'OR',
      segmentComponents: [
        {
          id: 245800,
          type: 'LOCATION',
          description: 'city is Santa Fe, New Mexico, United States',
          syncing: false,
          parameters: {
            isVerb: 'IS',
            subscriberProperty: 'CITY',
            locationValues: ['24226'],
            stateId: 22602,
            countryId: 2390,
            locations: [],
          },
        },
      ],
    },
    {
      id: 82731,
      operator: 'OR',
      segmentComponents: [
        {
          id: 236548,
          type: 'CUSTOM',
          description:
            'has clicked a text message from Attentive at least once in the last 365 days',
          syncing: false,
          parameters: {
            hasVerb: 'HAS',
            subscriberAction: 'CLICKED',
            timeComparator: 'IN_THE_LAST',
            durationTime: 365,
            durationUnit: 'DAYS',
            ignoreYear: false,
            frequencyComparator: 'AT_LEAST_ONCE',
            subscriberActionSource: 'ATTENTIVE',
            subscriberActionChannel: 'TEXT',
            locations: [],
          },
        },
      ],
    },
    {
      id: 82733,
      operator: 'OR',
      segmentComponents: [
        {
          id: 245806,
          type: 'CUSTOM',
          description:
            'has sent a text message to Attentive which does contain keyword or phrase "hi" at least once in the last 365 days',
          syncing: false,
          parameters: {
            hasVerb: 'HAS',
            subscriberAction: 'SENT_MESSAGE',
            timeComparator: 'IN_THE_LAST',
            durationTime: 365,
            durationUnit: 'DAYS',
            ignoreYear: false,
            frequencyComparator: 'AT_LEAST_ONCE',
            stringComparator: {
              comparator: 'STRING_COMPARATOR_LIKE',
              value: 'hi',
              inverse: false,
              propertyName: 'MESSAGE_BODY',
            },
            subscriberActionSource: 'ATTENTIVE',
            subscriberActionChannel: 'TEXT',
            locations: [],
          },
        },
      ],
    },
    {
      id: 82732,
      operator: 'OR',
      segmentComponents: [
        {
          id: 230678,
          type: 'CUSTOM',
          description:
            'has subscribed to receive text messages from Attentive in the last 365 days',
          syncing: false,
          parameters: {
            hasVerb: 'HAS',
            subscriberAction: 'JOINED',
            timeComparator: 'IN_THE_LAST',
            durationTime: 365,
            durationUnit: 'DAYS',
            ignoreYear: false,
            subscriberActionSource: 'ATTENTIVE',
            subscriberActionChannel: 'TEXT',
            locations: [],
          },
        },
      ],
    },
    {
      id: 82703,
      operator: 'OR',
      segmentComponents: [
        {
          id: 234073,
          type: 'CUSTOM',
          description:
            'has received a text message from Attentive at least once in the last 365 days',
          syncing: false,
          parameters: {
            hasVerb: 'HAS',
            subscriberAction: 'SENT',
            timeComparator: 'IN_THE_LAST',
            durationTime: 365,
            durationUnit: 'DAYS',
            ignoreYear: false,
            frequencyComparator: 'AT_LEAST_ONCE',
            subscriberActionSource: 'ATTENTIVE',
            subscriberActionChannel: 'TEXT',
            locations: [],
          },
        },
      ],
    },
    {
      id: 82734,
      operator: 'OR',
      segmentComponents: [
        {
          id: 215448,
          type: 'CUSTOM',
          description: 'has received an email from Attentive at least once in the last 90 days',
          syncing: false,
          parameters: {
            hasVerb: 'HAS',
            subscriberAction: 'SENT',
            timeComparator: 'IN_THE_LAST',
            durationTime: 90,
            durationUnit: 'DAYS',
            ignoreYear: false,
            frequencyComparator: 'AT_LEAST_ONCE',
            subscriberActionSource: 'ATTENTIVE',
            subscriberActionChannel: 'EMAIL',
            locations: [],
          },
        },
      ],
    },
    {
      id: 82702,
      operator: 'OR',
      segmentComponents: [
        {
          id: 40004,
          type: 'CUSTOM',
          description: 'text sign-up method is any mobile sign-up unit',
          syncing: false,
          parameters: {
            isVerb: 'IS',
            subscriberProperty: 'SOURCE',
            timeComparator: 'OVER_ALL_TIME',
            propertyAttribute: 'CREATIVE',
            propertyAttributeCondition: 'ANY_MOBILE_CREATIVE',
            subscriberActionSource: 'ATTENTIVE',
            subscriberActionChannel: 'TEXT',
            locations: [],
          },
        },
      ],
    },
  ],
  journeys: [],
  campaigns: [],
};
