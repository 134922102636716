import { castStringAsSerializedDateTime } from '@attentive/data';

import { CustomPropertyType } from '../../components/CustomAttributeDialog/components/ExistingCustomAttributeDialog/__generated__/ExistingCustomAttributeDialog_TargetingCommon_Query.graphql';

type MockCustomAttribute = {
  node: {
    id: string;
    name: string;
    propertyType: CustomPropertyType;
    created: SerializedDateTime;
    __typename: 'CustomPropertyDefinition';
  };
};

export const mockCustomAttributes: MockCustomAttribute[] = [
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uNTI5',
      name: 'Enum attribute #1',
      propertyType: 'TYPE_ENUM',
      created: castStringAsSerializedDateTime('2022-08-22T17:22:26Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uNTI4',
      name: 'Number attribute #2',
      propertyType: 'TYPE_NUMBER',
      created: castStringAsSerializedDateTime('2022-08-22T17:18:21Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uNTI2',
      name: 'String attribute #3',
      propertyType: 'TYPE_STRING',
      created: castStringAsSerializedDateTime('2022-08-12T17:07:10.07185Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uNTIw',
      name: 'Date attribute #4',
      propertyType: 'TYPE_DATE',
      created: castStringAsSerializedDateTime('2022-07-28T18:09:30.648172Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uNTI11234',
      name: 'Long attribute #1',
      propertyType: 'TYPE_LONG',
      created: castStringAsSerializedDateTime('2024-01-28T18:09:30.648172Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uNTE5',
      name: 'Number attribute #5',
      propertyType: 'TYPE_NUMBER',
      created: castStringAsSerializedDateTime('2022-07-28T18:07:46.2337Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uNTEw',
      name: 'String attribute #6',
      propertyType: 'TYPE_STRING',
      created: castStringAsSerializedDateTime('2022-07-15T18:16:57.156848Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uNDk1',
      name: 'Boolean attribute #7',
      propertyType: 'TYPE_BOOL',
      created: castStringAsSerializedDateTime('2022-07-08T14:26:54.156273Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uNDkz',
      name: 'Number attribute #8',
      propertyType: 'TYPE_NUMBER',
      created: castStringAsSerializedDateTime('2022-06-30T20:11:47.029869Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzc2',
      name: 'String attribute #9',
      propertyType: 'TYPE_STRING',
      created: castStringAsSerializedDateTime('2022-06-14T14:02:43.52761Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzc1',
      name: 'Number attribute #10',
      propertyType: 'TYPE_NUMBER',
      created: castStringAsSerializedDateTime('2022-06-14T13:42:15.752878Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzc0',
      name: 'Boolean attribute #11',
      propertyType: 'TYPE_BOOL',
      created: castStringAsSerializedDateTime('2022-06-14T13:40:57.021054Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzcz',
      name: 'Number attribute #12',
      propertyType: 'TYPE_NUMBER',
      created: castStringAsSerializedDateTime('2022-06-10T14:35:34.957722Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzcy',
      name: 'Enum attribute #13',
      propertyType: 'TYPE_ENUM',
      created: castStringAsSerializedDateTime('2022-06-09T23:48:18.260246Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzY2',
      name: 'Enum attribute #14',
      propertyType: 'TYPE_ENUM',
      created: castStringAsSerializedDateTime('2022-06-09T21:26:24.488207Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzY1',
      name: 'Enum attribute #15',
      propertyType: 'TYPE_ENUM',
      created: castStringAsSerializedDateTime('2022-06-07T00:11:17.668438Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzYz',
      name: 'Enum attribute #16',
      propertyType: 'TYPE_ENUM',
      created: castStringAsSerializedDateTime('2022-05-30T14:44:13.268324Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzYw',
      name: 'Enum attribute #17',
      propertyType: 'TYPE_ENUM',
      created: castStringAsSerializedDateTime('2022-05-25T12:33:42.829244Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzU4',
      name: 'Enum attribute #18',
      propertyType: 'TYPE_ENUM',
      created: castStringAsSerializedDateTime('2022-05-23T18:10:19.964732Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzU3',
      name: 'Enum attribute #19',
      propertyType: 'TYPE_ENUM',
      created: castStringAsSerializedDateTime('2022-05-23T18:10:04.733588Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzU1',
      name: 'Number attribute #20',
      propertyType: 'TYPE_NUMBER',
      created: castStringAsSerializedDateTime('2022-05-23T18:07:05.21646Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzU2',
      name: 'Boolean attribute #21',
      propertyType: 'TYPE_BOOL',
      created: castStringAsSerializedDateTime('2022-05-23T18:07:33.688174Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzU0',
      name: 'Date attribute #22',
      propertyType: 'TYPE_DATE',
      created: castStringAsSerializedDateTime('2022-05-23T18:06:34.934179Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzUz',
      name: 'String attribute #23',
      propertyType: 'TYPE_STRING',
      created: castStringAsSerializedDateTime('2022-05-23T18:06:14.334807Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzUw',
      name: 'String attribute #24',
      propertyType: 'TYPE_STRING',
      created: castStringAsSerializedDateTime('2022-05-22T18:12:24.894614Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzQ4',
      name: 'Enum attribute #25',
      propertyType: 'TYPE_ENUM',
      created: castStringAsSerializedDateTime('2022-05-19T16:39:36.744482Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzQ3',
      name: 'Enum attribute #26',
      propertyType: 'TYPE_ENUM',
      created: castStringAsSerializedDateTime('2022-05-19T16:39:36.679144Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzQ0',
      name: 'Enum attribute #27',
      propertyType: 'TYPE_ENUM',
      created: castStringAsSerializedDateTime('2022-05-18T21:03:46.32456Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzQy',
      name: 'String attribute #28',
      propertyType: 'TYPE_STRING',
      created: castStringAsSerializedDateTime('2022-05-16T19:07:59.259141Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzQx',
      name: 'String attribute #29',
      propertyType: 'TYPE_STRING',
      created: castStringAsSerializedDateTime('2022-05-16T19:06:40.067978Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzM4',
      name: 'String attribute #30',
      propertyType: 'TYPE_STRING',
      created: castStringAsSerializedDateTime('2022-05-13T20:47:59.991684Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzM0',
      name: 'String attribute #31',
      propertyType: 'TYPE_STRING',
      created: castStringAsSerializedDateTime('2022-05-12T21:36:39.96956Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzMw',
      name: 'Number attribute #32',
      propertyType: 'TYPE_NUMBER',
      created: castStringAsSerializedDateTime('2022-05-09T14:18:52.247414Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzI5',
      name: 'Date attribute #33',
      propertyType: 'TYPE_DATE',
      created: castStringAsSerializedDateTime('2022-04-22T19:20:12.267684Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzEx',
      name: 'Enum attribute #34',
      propertyType: 'TYPE_ENUM',
      created: castStringAsSerializedDateTime('2022-03-18T19:29:23.400071Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzA4',
      name: 'Enum attribute #35',
      propertyType: 'TYPE_ENUM',
      created: castStringAsSerializedDateTime('2022-03-16T21:52:00.644342Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzA2',
      name: 'Enum attribute #36',
      propertyType: 'TYPE_ENUM',
      created: castStringAsSerializedDateTime('2022-03-11T21:53:43.832752Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzA1',
      name: 'Enum attribute #37',
      propertyType: 'TYPE_ENUM',
      created: castStringAsSerializedDateTime('2022-03-11T21:39:02.671536Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzA0',
      name: 'Enum attribute #38',
      propertyType: 'TYPE_ENUM',
      created: castStringAsSerializedDateTime('2022-03-11T21:27:58.265953Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzAz',
      name: 'String attribute #39',
      propertyType: 'TYPE_STRING',
      created: castStringAsSerializedDateTime('2022-03-10T19:48:47.550008Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
  {
    node: {
      id: 'MjQ6Q3VzdG9tUHJvcGVydHlEZWZpbml0aW9uMzAw',
      name: 'Enum attribute #40',
      propertyType: 'TYPE_ENUM',
      created: castStringAsSerializedDateTime('2022-03-04T16:56:37.874104Z'),
      __typename: 'CustomPropertyDefinition',
    },
  },
];
