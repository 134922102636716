import { useCompanyFeatureFlag } from '@attentive/acore-utils';

import { SubscriberChannelRadioType } from './constants';

export const useDefaultSubscriberChannel = (): SubscriberChannelRadioType => {
  const enableEmail = useCompanyFeatureFlag('ENABLE_TRIGGERED_EMAIL');
  const isEmailOnly = useCompanyFeatureFlag('ENABLE_EMAIL_ONLY_COMPANY');

  if (!enableEmail) {
    return 'TEXT';
  }
  if (isEmailOnly) {
    return 'EMAIL';
  }
  return 'ALL';
};
