/**
 * @generated SignedSource<<5c120f72e4cbac905e792de4212eaa6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SegmentExportDialogBody_TargetingCommon_Query$variables = {
  companyId: string;
  segmentId: string;
};
export type SegmentExportDialogBody_TargetingCommon_Query$data = {
  readonly company: {
    readonly __typename: "Company";
    readonly " $fragmentSpreads": FragmentRefs<"RecipientsMultiSelectAdapter_ReportingPlatform_company">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly segment: {
    readonly " $fragmentSpreads": FragmentRefs<"useGetSegmentCountMetric_TargetingCommon_Segment">;
  } | null;
};
export type SegmentExportDialogBody_TargetingCommon_Query$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly id: string;
    readonly possibleReportRecipients: ReadonlyArray<{
      readonly account: {
        readonly email: string;
        readonly id: string;
      } | null;
    }> | null;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
  readonly segment: {
    readonly __typename: "Segment";
    readonly id: string;
    readonly segmentCount: {
      readonly email: {
        readonly activeSubscribers: number;
        readonly activeSubscribersNotFatigued: number;
      } | null;
      readonly sms: {
        readonly activeSubscribers: number;
        readonly activeSubscribersNotFatigued: number;
      };
      readonly smsAndEmail: {
        readonly activeSubscribers: number;
        readonly activeSubscribersNotFatigued: number;
      } | null;
    } | null;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type SegmentExportDialogBody_TargetingCommon_Query = {
  rawResponse: SegmentExportDialogBody_TargetingCommon_Query$rawResponse;
  response: SegmentExportDialogBody_TargetingCommon_Query$data;
  variables: SegmentExportDialogBody_TargetingCommon_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "segmentId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "segmentId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "activeSubscribers",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "activeSubscribersNotFatigued",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SegmentExportDialogBody_TargetingCommon_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "company",
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RecipientsMultiSelectAdapter_ReportingPlatform_company"
                }
              ],
              "type": "Company",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "company"
      },
      {
        "alias": "segment",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "Defer",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useGetSegmentCountMetric_TargetingCommon_Segment"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SegmentExportDialogBody_TargetingCommon_Query",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportRecipient",
                "kind": "LinkedField",
                "name": "possibleReportRecipients",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "segment",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "if": null,
            "kind": "Defer",
            "label": "SegmentExportDialogBody_TargetingCommon_Query$defer$useGetSegmentCountMetric_TargetingCommon_Segment",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SegmentCountPayload",
                    "kind": "LinkedField",
                    "name": "segmentCount",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SegmentCountItem",
                        "kind": "LinkedField",
                        "name": "sms",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SegmentCountItem",
                        "kind": "LinkedField",
                        "name": "email",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SegmentCountItem",
                        "kind": "LinkedField",
                        "name": "smsAndEmail",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Segment",
                "abstractKey": null
              }
            ]
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cc529c32a4b6c69ba49f50ddb799176c",
    "id": null,
    "metadata": {},
    "name": "SegmentExportDialogBody_TargetingCommon_Query",
    "operationKind": "query",
    "text": "query SegmentExportDialogBody_TargetingCommon_Query(\n  $companyId: ID!\n  $segmentId: ID!\n) {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      ...RecipientsMultiSelectAdapter_ReportingPlatform_company\n    }\n    id\n  }\n  segment: node(id: $segmentId) {\n    __typename\n    ...useGetSegmentCountMetric_TargetingCommon_Segment @defer(label: \"SegmentExportDialogBody_TargetingCommon_Query$defer$useGetSegmentCountMetric_TargetingCommon_Segment\")\n    id\n  }\n}\n\nfragment RecipientsMultiSelectAdapter_ReportingPlatform_company on Company {\n  ...RecipientsMultiSelect_ReportingPlatform_company\n  possibleReportRecipients {\n    account {\n      id\n      email\n    }\n  }\n}\n\nfragment RecipientsMultiSelect_ReportingPlatform_company on Company {\n  possibleReportRecipients {\n    account {\n      id\n      email\n    }\n  }\n}\n\nfragment useGetSegmentCountMetric_TargetingCommon_Segment on Segment {\n  segmentCount {\n    sms {\n      activeSubscribers\n      activeSubscribersNotFatigued\n    }\n    email {\n      activeSubscribers\n      activeSubscribersNotFatigued\n    }\n    smsAndEmail {\n      activeSubscribers\n      activeSubscribersNotFatigued\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d32a1276b3a0e3a3dfa5a7bbcaa63900";

export default node;
