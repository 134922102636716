import { mswRest, parseMSWHeaders } from '@attentive/mock-data';

import {
  completeSegment,
  segmentWithCustomAttributes,
  segmentWithOneAttribute,
  segmentWithRecharge,
  segmentWithSmsConditions,
} from '../data/segment';
import { segmentWithSeveralConditions } from '../segmentTestMocks';

export interface GetSegmentHeaders {
  getSegmentDelay?: number;
}

export const getSegment = mswRest.get(
  'https://ui-api-devel.attentivemobile.com/user-profile/v1/segments/:segmentId',
  (req, res, ctx) => {
    const { segmentId } = req.params;
    const { getSegmentDelay = 0 }: GetSegmentHeaders = parseMSWHeaders(req.headers);

    const segment =
      [
        completeSegment,
        segmentWithCustomAttributes,
        segmentWithOneAttribute,
        segmentWithSeveralConditions,
        segmentWithRecharge,
        segmentWithSmsConditions,
      ].find(({ id }) => id === parseInt(segmentId)) || completeSegment;

    return res(ctx.delay(getSegmentDelay), ctx.json(segment));
  }
);
