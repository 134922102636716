/**
 * @generated SignedSource<<7a4ef3259cd0463201805f2a01d14752>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecipientsMultiSelectAdapter_ReportingPlatform_company$data = {
  readonly possibleReportRecipients: ReadonlyArray<{
    readonly account: {
      readonly email: string;
      readonly id: string;
    } | null;
  }> | null;
  readonly " $fragmentSpreads": FragmentRefs<"RecipientsMultiSelect_ReportingPlatform_company">;
  readonly " $fragmentType": "RecipientsMultiSelectAdapter_ReportingPlatform_company";
};
export type RecipientsMultiSelectAdapter_ReportingPlatform_company$key = {
  readonly " $data"?: RecipientsMultiSelectAdapter_ReportingPlatform_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecipientsMultiSelectAdapter_ReportingPlatform_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecipientsMultiSelectAdapter_ReportingPlatform_company",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RecipientsMultiSelect_ReportingPlatform_company"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportRecipient",
      "kind": "LinkedField",
      "name": "possibleReportRecipients",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "dc617d9edf0d9ea4fa5afd07c7ce9163";

export default node;
