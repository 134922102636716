/**
 * @generated SignedSource<<4eddf588d10fec32cfa005ecc5b2da99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type Channel = "CHANNEL_EMAIL" | "CHANNEL_PUSH" | "CHANNEL_RBM" | "CHANNEL_TEXT" | "CHANNEL_UNKNOWN" | "%future added value";
export type StandardErrorStatus = "STANDARD_ERROR_STATUS_ABORTED" | "STANDARD_ERROR_STATUS_ALREADY_EXISTS" | "STANDARD_ERROR_STATUS_CANCELLED" | "STANDARD_ERROR_STATUS_DATA_LOSS" | "STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED" | "STANDARD_ERROR_STATUS_FAILED_PRECONDITION" | "STANDARD_ERROR_STATUS_INTERNAL" | "STANDARD_ERROR_STATUS_INVALID_ARGUMENT" | "STANDARD_ERROR_STATUS_NOT_FOUND" | "STANDARD_ERROR_STATUS_NOT_SET" | "STANDARD_ERROR_STATUS_OUT_OF_RANGE" | "STANDARD_ERROR_STATUS_PERMISSION_DENIED" | "STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED" | "STANDARD_ERROR_STATUS_UNAUTHENTICATED" | "STANDARD_ERROR_STATUS_UNAVAILABLE" | "STANDARD_ERROR_STATUS_UNIMPLEMENTED" | "STANDARD_ERROR_STATUS_UNKNOWN" | "%future added value";
export type ExportSegmentInput = {
  baseAudienceDefinition: BaseAudienceDefinitionInput;
  companyId: string;
  recipientEmails: ReadonlyArray<string>;
  segmentId: string;
};
export type BaseAudienceDefinitionInput = {
  channels?: ReadonlyArray<Channel> | null;
  isExcludingFatiguedUsers: boolean;
  isIncludingOptOutUsers: boolean;
};
export type useExportSegment_TargetingCommon_Mutation$variables = {
  input: ExportSegmentInput;
};
export type useExportSegment_TargetingCommon_Mutation$data = {
  readonly exportSegment: {
    readonly __typename: "ExportSegmentSuccess";
    readonly message?: string;
    readonly segmentId?: string;
    readonly status?: StandardErrorStatus;
    readonly title?: string;
    readonly traceId?: string;
  } | null;
};
export type useExportSegment_TargetingCommon_Mutation$rawResponse = {
  readonly exportSegment: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
    readonly title: string;
    readonly traceId: string;
  } | {
    readonly __typename: "ExportSegmentSuccess";
    readonly segmentId: string;
  } | {
    readonly __typename: string;
  } | null;
};
export type useExportSegment_TargetingCommon_Mutation = {
  rawResponse: useExportSegment_TargetingCommon_Mutation$rawResponse;
  response: useExportSegment_TargetingCommon_Mutation$data;
  variables: useExportSegment_TargetingCommon_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "segmentId",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "traceId",
      "storageKey": null
    }
  ],
  "type": "DefaultErrorFailure",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useExportSegment_TargetingCommon_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "exportSegment",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "ExportSegmentSuccess",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useExportSegment_TargetingCommon_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "exportSegment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "ExportSegmentSuccess",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d41b82bece842a214d1e434690842c2b",
    "id": null,
    "metadata": {},
    "name": "useExportSegment_TargetingCommon_Mutation",
    "operationKind": "mutation",
    "text": "mutation useExportSegment_TargetingCommon_Mutation(\n  $input: ExportSegmentInput!\n) {\n  exportSegment(input: $input) {\n    __typename\n    ... on ExportSegmentSuccess {\n      __typename\n      segmentId\n    }\n    ... on DefaultErrorFailure {\n      message\n      status\n      title\n      traceId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e667a7f80c5e713b047d76fdc9b0a90a";

export default node;
