import React, { FC } from 'react';

import { Text, IconPopover, PicnicCss } from '@attentive/picnic';

export const SubscriberChannelPopover: FC<{ css?: PicnicCss }> = ({ css }) => (
  <IconPopover
    iconName="CircleInformation"
    size="small"
    align="center"
    css={css}
    description="Subscriber channel descriptions"
  >
    <Text>
      <b>All subscribers:</b> Subscribers currently opted into Email or SMS
      <br />
      <b>SMS:</b> Subscribers currently opted into SMS
      <br />
      <b>Email:</b> Subscribers currently opted into Email
      <br />
      <b>Multi-channel:</b> Subscribers currently opted into both Email and SMS
    </Text>
  </IconPopover>
);
